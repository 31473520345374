/* eslint-disable no-constant-condition */
/* eslint-disable no-await-in-loop */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { ethers } from 'ethers';
import metadata from '../files/metadata.json';
import MyNFTTile from '../components/gallery/MyNFTTile';
import Nav from '../components/nav';
import Footer from '../components/footer';
import abi from '../files/HealingCodes.json';

const IS_PROD = true;
const CONTRACT_ADDRESS = IS_PROD ? '0x92927958630fD8Eef91305fb7E1Cac323F779305' : '0xE082F93DDa091959b1Ae87E88b5902d59237CFEa';

const MyNFT = (props) => {
  const [codes, setCodes] = useState(false);

  useEffect(async () => {
    if (!props.web3Provider) {
      setCodes(<div id="mynftmessage">Please connect your wallet to view your codes</div>);
    } else {
      const codes1 = [];
      let contract;
      try {
        contract = new ethers.Contract(
          CONTRACT_ADDRESS,
          abi.abi,
          props.web3Provider.getSigner(),
        );
      } catch (err) {
        console.log(err);
        console.error('Error initializing contract');
      }
      let i = 0;
      while (true) {
        let tokenId;
        try {
          tokenId = await contract.tokenOfOwnerByIndex(props.address, i);
        } catch (err) {
          console.dir(err);
          break;
        }
        const metadata1 = metadata.meta_array[tokenId];
        codes1.push(metadata1);
        i += 1;
        setCodes(codes1.map((code) => {
          return <MyNFTTile key={code.id} code={code} />;
        }));
      }
    }
  }, [props.web3Provider]);

  return (
    <div>
      <Nav />
      <div className="mynfts" id="gallery-container">
        {codes}
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => (
  {
    provider: state.web3.provider,
    web3Provider: state.web3.web3Provider,
    address: state.web3.address,
    chainId: state.web3.chainId,
  }
);

export default connect(mapStateToProps, null)(MyNFT);
