import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTwitter, faDiscord, faInstagram,
} from '@fortawesome/free-brands-svg-icons';
import Mint from './mintbutton';

const section1 = (props) => {
  return (
    <div id="section1container">
      <div id="S1left">
        <div id="S1logo" />
        <span id="S1title">HEALING CODES<div id="S1tm">TM</div></span>
        <div id="S1subtitle">LOVESCRIPT BY SABET</div>
        <div id="S1text">A collection of 4850 NFTs based on 333 Hand Painted Healing Codes by
          Ali Sabet. Each Healing Code will have intrinsic utility - as they&apos;re charged
          with healing energy. Healing Codes will also have practical utility - we will
          be holding annual Healing Seminars with prominent spiritual leaders and
          teachers. Other events around Healing and Wellness topics will also be
          part of the roadmap.
        </div>
        <div id="S1line" />
        <div id="mintcontainer">
          <div>
            <Mint />
          </div>
          <div id="S1info">
            <div id="S1genesis">GENESIS COLLECTION</div>
            <div id="S1details">
              <div id="S1count">4,850 NFTS</div>
              <div id="S1vertical" />
              <div id="S1price">.0888 ETH</div>
            </div>
          </div>
        </div>
      </div>
      <div id="S1bg">
        <div id="topsocials" className="socials">
          <a href="https://twitter.com/healingcodesnft" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faTwitter} size="1x" />
          </a>
          <a href="https://discord.com/invite/sabet" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faDiscord} size="1x" />
          </a>
          <a href="https://www.instagram.com/healingcodesnft/?hl=en" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} size="1x" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default section1;
