import { ActionTypes } from '../actions';
import metadata from '../files/metadata.json';

const initialState = {
  array: [...metadata.meta_array],
  page: 0,
  sort: { value: 'Number', label: 'Number' },
};

const GalleryReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_PAGE:
      return {
        ...state,
        page: action.payload.page,
      };
    case ActionTypes.SET_SORT:
      return {
        ...state,
        sort: action.payload.sort,
      };
    default:
      return state;
  }
};

export default GalleryReducer;
