/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Img from 'react-cool-img';
import Marquee from 'react-fast-marquee';

const section1 = (props) => {
  return (
    <div id="section2container">
      <Marquee style={{ marginBottom: '30px' }} gradient={false} speed={20}>
        <Img
          className="pictures"
          src="https://healing-codes.s3.us-east-2.amazonaws.com/300-min.jpg"
        />
        <Img
          className="pictures"
          src="https://healing-codes.s3.us-east-2.amazonaws.com/222-min.jpg"
        />
        <Img
          className="pictures"
          src="https://healing-codes.s3.us-east-2.amazonaws.com/111-min.jpg"
        />
        <Img
          className="pictures"
          src="https://healing-codes.s3.us-east-2.amazonaws.com/320-min.jpg"
        />
        <Img
          className="pictures"
          src="https://healing-codes.s3.us-east-2.amazonaws.com/42-min.jpg"
        />
        <Img
          className="pictures"
          src="https://healing-codes.s3.us-east-2.amazonaws.com/69-min.jpg"
        />
        <Img
          className="pictures"
          src="https://healing-codes.s3.us-east-2.amazonaws.com/141-min.jpg"
        />
        <Img
          className="pictures"
          src="https://healing-codes.s3.us-east-2.amazonaws.com/194-min.jpg"
        />
        <Img
          className="pictures"
          src="https://healing-codes.s3.us-east-2.amazonaws.com/50-min.jpg"
        />
        <Img
          className="pictures"
          src="https://healing-codes.s3.us-east-2.amazonaws.com/60-min.jpg"
        />
        <Img
          className="pictures"
          src="https://healing-codes.s3.us-east-2.amazonaws.com/70-min.jpg"
        />
        <Img
          className="pictures"
          src="https://healing-codes.s3.us-east-2.amazonaws.com/80-min.jpg"
        />
        <Img
          className="pictures"
          src="https://healing-codes.s3.us-east-2.amazonaws.com/10-min.jpg"
        />
        <Img
          className="pictures"
          src="https://healing-codes.s3.us-east-2.amazonaws.com/4-min.jpg"
        />
        <Img
          className="pictures"
          src="https://healing-codes.s3.us-east-2.amazonaws.com/38-min.jpg"
        />
      </Marquee>
    </div>
  );
};

export default section1;
