/* eslint-disable max-len */
import React from 'react';
import Img from 'react-cool-img';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTwitter, faDiscord, faInstagram,
} from '@fortawesome/free-brands-svg-icons';
import S2top from '../../files/S2top.png';
import S2code1 from '../../files/S2code1.png';
import S2code2 from '../../files/S2code2.png';
import Sabet from '../../files/sabet.png';

const section1 = (props) => {
  return (
    <div id="section3container">
      <div id="S3vert1">
        <Img
          id="S2topcode"
          src={S2code1}
        />
        <Img
          id="S2topimg"
          src={S2top}
        />
        <div id="S2TC1">
          <div id="S2TC1title">WHAT ARE</div>
          <div id="S2TC1title2"> HEALING CODES?</div>
          <div id="S2TC1text">In 2015, at 3:00 am, four unique symbols of love appeared to Sabet while he was sketching. These symbols became the foundation of his famous lovescript, which Sabet describes as a mysterious language that flows directly from the universe and onto the canvas. As Sabet’s lovescript spread through the art world, collectors have described a deep emotional connection to the individual healing codes. Some are read in known languages such as Chinese, Japanese or even English, and some have seen mysterious dates and names, while others describe profound moments of clarity and healing. In this collection, Sabet will be breaking down the lovescript and offering each collector a code to guide them on their journey.
          </div>
        </div>
      </div>
      <div id="S3vert2">
        <Img
          id="S2bottomcode"
          src={S2code2}
        />
        <Img
          id="S2bottomimg"
          src={Sabet}
        />
        <div id="S2TC2">
          <div id="S2TC2title">ALI SABET</div>
          <div id="S2TC2title2">PAINTER / DESIGNER / ENERGY HEALER</div>
          <div id="S2TC2text">Ali Sabet is a painter, designer, and one of the Top-Selling NFT artists in the world. Sabet has always been fascinated with the link between creativity, art, and healing. After returning from a trip to Tokyo, Sabet had a profound moment of clarity that changed his life. Since then, he has been able to write in a mysterious language known as Lovescript, which contains healing codes that have offered collectors profound experiences.</div>
          <div id="sabetsocials">
            <a href="https://twitter.com/sabet" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faTwitter} size="1x" />
            </a>
            <a href="https://discord.com/invite/sabet" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faDiscord} size="1x" />
            </a>
            <a href="https://www.instagram.com/sabet/?hl=en" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} size="1x" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default section1;
