/* eslint-disable max-len */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTwitter, faDiscord, faInstagram,
} from '@fortawesome/free-brands-svg-icons';
import { NavHashLink } from 'react-router-hash-link';

const section1 = (props) => {
  return (
    <div id="footer">
      <div id="footerlogo" />
      <div id="Fmintcontainer">
        <NavHashLink
          smooth
          to="/"
        > <div id="Fmintbutton">MINT FEB 28</div>
        </NavHashLink>

        <div id="Finfo">
          <div id="Fgenesis">GENESIS COLLECTION</div>
          <div id="Fdetails">
            <div id="Fcount">4,850 NFTS</div>
            <div id="Fvertical" />
            <div id="Fprice">.0888 ETH</div>
          </div>
        </div>
      </div>
      <div className="socials">
        <a href="https://twitter.com/healingcodesnft" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faTwitter} size="1x" />
        </a>
        <a href="https://discord.com/invite/sabet" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faDiscord} size="1x" />
        </a>
        <a href="https://www.instagram.com/healingcodesnft/?hl=en" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faInstagram} size="1x" />
        </a>
      </div>
    </div>
  );
};

export default section1;
