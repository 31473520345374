/* eslint-disable jsx-a11y/interactive-supports-focus */
import React from 'react';
// import Nav from '../components/nav';
import Section1 from '../components/welcome/section1';
import Section2 from '../components/welcome/section2';
import Section3 from '../components/welcome/section3';
import Section4 from '../components/welcome/section4';
import Footer from '../components/footer';
import Nav from '../components/navwelcome';

const Welcome = (props) => {
  return (
    <div id="maincontainer">
      <Nav />
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Footer />
    </div>
  );
};

export default Welcome;
