import React from 'react';
import './styles/welcome/globals.scss';
import './styles/gallery/nav.scss';
import './styles/welcome/section1.scss';
import './styles/gallery/code.scss';
import './styles/gallery/gallery.scss';
import './styles/details/code-details.scss';
import './styles/welcome/section2.scss';
import './styles/welcome/section3.scss';
import './styles/welcome/mint.scss';
import './styles/welcome/section4.scss';
import './styles/welcome/footer.scss';
import './styles/welcome/homenav.scss';
import './styles/welcome/_slick.scss';
import './styles/welcome/_slickTheme.scss';
import './styles/rarity.scss';
import './styles/calendar.scss';
import {
  BrowserRouter as Router, Route, Switch, NavLink,
} from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/pro-regular-svg-icons';
import Welcome from './screens/welcome';
import Gallery from './screens/gallery';
import Calendar from './screens/calendar';
import MyNFTs from './screens/mynft';
import ScrollToTop from './scrolltotop';
import Detail from './screens/codedetails';
import MyDetail from './screens/mycodedetails';

// import Nav from './components/nav';
import Rarity from './screens/rarity';

library.add(far);

const FallBack = (props) => {
  return (
    <div id="fallbackcontainer">
      <div id="fallbacktext">URL Not Found</div>
      <NavLink id="fallbackbutton" to="/">Go Back Home</NavLink>
    </div>
  );
};

// const Placeholder = (props) => {
//   return (
//     <div>
//       <Nav />
//       <div id="placeholdercontainer">
//         <div id="phtitle">COMING SOON</div>
//         <div id="phtitle2">After all Codes are Revealed</div>
//         <NavLink id="placeholderbutton" to="/">Go Back Home</NavLink>
//       </div>
//     </div>

//   );
// };

const App = (props) => {
  return (
    <Router>
      <ScrollToTop>
        <Switch>
          <Route exact path="/" component={Welcome} />
          <Route exact path="/gallery" component={Gallery} />
          <Route exact path="/calendar" component={Calendar} />
          <Route exact path="/mynfts" component={MyNFTs} />
          <Route exact path="/rarities" component={Rarity} />
          <Route exact path="/code/:id" component={Detail} />
          <Route exact path="/mycode/:id" component={MyDetail} />
          <Route component={FallBack} />
        </Switch>
      </ScrollToTop>
    </Router>
  );
};

export default App;
