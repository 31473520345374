/* eslint-disable no-constant-condition */
/* eslint-disable no-await-in-loop */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { ethers } from 'ethers';
import Nav from '../components/nav';
import Footer from '../components/footer';
import abi from '../files/HealingCodes.json';

const IS_PROD = true;
const CONTRACT_ADDRESS = IS_PROD ? '0x92927958630fD8Eef91305fb7E1Cac323F779305' : '0xE082F93DDa091959b1Ae87E88b5902d59237CFEa';

const MyNFT = (props) => {
  const [auth, setAuth] = useState(false);

  useEffect(async () => {
    if (!props.web3Provider) {
      setAuth(false);
    } else {
      let contract, tokenId;
      try {
        contract = new ethers.Contract(
          CONTRACT_ADDRESS,
          abi.abi,
          props.web3Provider.getSigner(),
        );
      } catch (err) {
        console.log(err);
        console.error('Error initializing contract');
      }
      try {
        tokenId = await contract.tokenOfOwnerByIndex(props.address, 0);
      } catch (err) {
        console.dir(err);
      }
      if (tokenId >= 0) {
        setAuth(true);
      }
    }
  }, [props.web3Provider]);

  return (
    <div>
      <Nav />
      {(auth) ? (
        <div className="calendar-container">
          <iframe id="calendar" title="calendar" src="https://calendar.google.com/calendar/embed?src=c_pp3tdi694iq6suvqmngdr2m9f8%40group.calendar.google.com&ctz=America%2FMexico_City" frameBorder="0" scrolling="no" />

        </div>
      ) : (
        <div className="calendar-container" style={{ textAlign: 'center', fontSize: '22px' }}>
          Please connect a wallet holding a Healing Code NFT to access the calendar.
        </div>
      )}
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => (
  {
    provider: state.web3.provider,
    web3Provider: state.web3.web3Provider,
    address: state.web3.address,
    chainId: state.web3.chainId,
  }
);

export default connect(mapStateToProps, null)(MyNFT);
