/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import { setArray, setPage, setSort } from '../actions/index';
import GalleryTile from '../components/gallery/GalleryTile';
import Nav from '../components/nav';
import Footer from '../components/footer';
import useWindowDimensions from '../lib/windowdimensions';

const options = [
  { value: 'Number', label: 'Number' },
  { value: 'Frequency', label: 'Frequency' },
  { value: 'Vibration', label: 'Vibration' },
  { value: 'Crown', label: 'Crown' },
  { value: 'Third Eye', label: 'Third Eye' },
  { value: 'Throat', label: 'Throat' },
  { value: 'Heart', label: 'Heart' },
  { value: 'Solar Plexus', label: 'Solar Plexus' },
  { value: 'Sacral', label: 'Sacral' },
  { value: 'Root', label: 'Root' },
  { value: 'Edition', label: 'Edition' },
];

const customStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: '#E2dcdc',
    borderColor: 'black',
    color: 'black',
  }),

  option: (provided) => ({
    ...provided,
    backgroundColor: '#E2dcdc',
    borderColor: 'black',
    color: 'black',
  }),

  menu: (provided) => ({
    ...provided,
    backgroundColor: '#E2dcdc',
    borderColor: 'black',
    color: 'black',
  }),

  dropdownIndicator: (provided) => ({
    ...provided,
    color: 'black',
  }),

  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: 'black',
  }),

};

const Gallery = (props) => {
  const [codes, setCodes] = useState(false);
  const [totalTiles, setTotalTiles] = useState(24);
  const [flip, setFlip] = useState('Ascending');
  const { height, width } = useWindowDimensions();
  //   const [page, setPage] = useState(0);
  //   const [array, setArray] = useState([...metadata.meta_array]);

  useEffect(() => {
    const numPerRow = Math.floor((width / 365));
    const total = numPerRow * 7;
    setTotalTiles(total);
    setCodes(props.array.slice((props.page * total), (props.page * total + total)).map((code) => {
      return (
        <GalleryTile key={code.id} code={code} />
      );
    }));
  }, [width]);

  const flipCodes = () => {
    if (flip === 'Ascending') {
      setArray(props.array.reverse());
      setCodes(props.array.slice((props.page * totalTiles), (props.page * totalTiles + totalTiles)).map((code) => {
        return <GalleryTile key={code.id} code={code} />;
      }));
      setFlip('Descending');
    } else {
      setArray(props.array.reverse());
      setCodes(props.array.slice((props.page * totalTiles), (props.page * totalTiles + totalTiles)).map((code) => {
        return <GalleryTile key={code.id} code={code} />;
      }));
      setFlip('Ascending');
    }
  };

  const sort = (value) => {
    console.log(props.array);
    if (value.value === 'Frequency') {
      setArray(props.array.sort((c1, c2) => { return c1.attributes[1].value - c2.attributes[1].value; }));
      setCodes(props.array.slice((props.page * totalTiles), (props.page * totalTiles + totalTiles)).map((code) => {
        return <GalleryTile key={code.id} code={code} />;
      }));
      props.setSort(options[1]);
    } else if (value.value === 'Vibration') {
      setArray(props.array.sort((c1, c2) => { return c1.attributes[3].value - c2.attributes[3].value; }));
      setCodes(props.array.slice((props.page * totalTiles), (props.page * totalTiles + totalTiles)).map((code) => {
        return <GalleryTile key={code.id} code={code} />;
      }));
      props.setSort(options[2]);
    } else if (value.value === 'Crown') {
      setArray(props.array.sort((c1, c2) => { return c1.attributes[4].value - c2.attributes[4].value; }));
      setCodes(props.array.slice((props.page * totalTiles), (props.page * totalTiles + totalTiles)).map((code) => {
        return <GalleryTile key={code.id} code={code} />;
      }));
      props.setSort(options[3]);
    } else if (value.value === 'Third Eye') {
      setArray(props.array.sort((c1, c2) => { return c1.attributes[5].value - c2.attributes[5].value; }));
      setCodes(props.array.slice((props.page * totalTiles), (props.page * totalTiles + totalTiles)).map((code) => {
        return <GalleryTile key={code.id} code={code} />;
      }));
      props.setSort(options[4]);
    } else if (value.value === 'Throat') {
      setArray(props.array.sort((c1, c2) => { return c1.attributes[6].value - c2.attributes[6].value; }));
      setCodes(props.array.slice((props.page * totalTiles), (props.page * totalTiles + totalTiles)).map((code) => {
        return <GalleryTile key={code.id} code={code} />;
      }));
      props.setSort(options[5]);
    } else if (value.value === 'Heart') {
      setArray(props.array.sort((c1, c2) => { return c1.attributes[7].value - c2.attributes[7].value; }));
      setCodes(props.array.slice((props.page * totalTiles), (props.page * totalTiles + totalTiles)).map((code) => {
        return <GalleryTile key={code.id} code={code} />;
      }));
      props.setSort(options[6]);
    } else if (value.value === 'Solar Plexus') {
      setArray(props.array.sort((c1, c2) => { return c1.attributes[8].value - c2.attributes[8].value; }));
      setCodes(props.array.slice((props.page * totalTiles), (props.page * totalTiles + totalTiles)).map((code) => {
        return <GalleryTile key={code.id} code={code} />;
      }));
      props.setSort(options[7]);
    } else if (value.value === 'Sacral') {
      setArray(props.array.sort((c1, c2) => { return c1.attributes[9].value - c2.attributes[9].value; }));
      setCodes(props.array.slice((props.page * totalTiles), (props.page * totalTiles + totalTiles)).map((code) => {
        return <GalleryTile key={code.id} code={code} />;
      }));
      props.setSort(options[8]);
    } else if (value.value === 'Root') {
      setArray(props.array.sort((c1, c2) => { return c1.attributes[10].value - c2.attributes[10].value; }));
      setCodes(props.array.slice((props.page * totalTiles), (props.page * totalTiles + totalTiles)).map((code) => {
        return <GalleryTile key={code.id} code={code} />;
      }));
      props.setSort(options[9]);
    } else if (value.value === 'Edition') {
      setArray(props.array.sort((c1, c2) => { return c1.attributes[11].value - c2.attributes[11].value; }));
      setCodes(props.array.slice((props.page * totalTiles), (props.page * totalTiles + totalTiles)).map((code) => {
        return <GalleryTile key={code.id} code={code} />;
      }));
      props.setSort(options[10]);
    } else if (value.value === 'Number') {
      setArray(props.array.sort((c1, c2) => { return c1.id - c2.id; }));
      setCodes(props.array.slice((props.page * totalTiles), (props.page * totalTiles + totalTiles)).map((code) => {
        return <GalleryTile key={code.id} code={code} />;
      }));
      props.setSort(options[0]);
    }
  };

  const nextPage = () => {
    console.log(props.page);
    if (props.page === 201) {
      setCodes(props.array.slice(((props.page + 1) * totalTiles), (4850)).map((code) => {
        return <GalleryTile key={code.id} code={code} />;
      }));
      props.setPage(props.page + 1);
    } else if (props.page === 202) {
      console.log('nope');
    } else {
      setCodes(props.array.slice(((props.page + 1) * totalTiles), ((props.page + 1) * totalTiles + totalTiles)).map((code) => {
        return <GalleryTile key={code.id} code={code} />;
      }));
      props.setPage(props.page + 1);
    }
  };

  const prevPage = () => {
    if (props.page > 0) {
      setCodes(props.array.slice(((props.page - 1) * totalTiles), ((props.page - 1) * totalTiles + totalTiles)).map((code) => {
        return <GalleryTile key={code.id} code={code} />;
      }));
      props.setPage(props.page - 1);
    }
  };

  return (
    <div>
      <Nav />
      <div id="gallery-container">
        <div id="filter-bar">
          <div id="sorttext">Sort By:</div>
          <Select
            styles={customStyles}
            defaultValue={props.currentsort}
            onChange={sort}
            options={options}
          />
          <div className="pagebutton" id="ascendbutton" onClick={flipCodes}>{flip}</div>
        </div>
        {codes}
        <div id="paging">
          <div className="pagebutton" onClick={prevPage}>Prev</div>
          <div className="pagebutton" onClick={nextPage}>Next</div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => (
  {
    array: state.gallery.array,
    page: state.gallery.page,
    currentsort: state.gallery.sort,
  }
);

export default connect(mapStateToProps, { setArray, setPage, setSort })(Gallery);
