/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import Img from 'react-cool-img';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import ScoreProgress from '../components/details/scoreprogress';
import FrequencyProgress from '../components/details/frequencyprogress';
import CrownProgress from '../components/details/crownprogress';
import ThirdEyeProgress from '../components/details/thirdeyeprogress';
import ThroatProgress from '../components/details/throatprogress';
import HeartProgress from '../components/details/heartprogress';
import SolarPlexusProgress from '../components/details/solarplexusprogress';
import SacralProgress from '../components/details/sacralprogress';
import RootProgress from '../components/details/rootprogress';
import metadata from '../files/metadata.json';
import Nav from '../components/nav';
import Footer from '../components/footer';

const Code = (props) => {
  console.log(props.match.params.id);
  console.log(metadata.meta_array[0]);
  const code = metadata.meta_array[props.match.params.id];
  const crownscore = code.attributes[4].value;
  const thirdscore = code.attributes[5].value;
  const throatscore = code.attributes[6].value;
  const heartscore = code.attributes[7].value;
  const solarplexusscore = code.attributes[8].value;
  const rootscore = code.attributes[10].value;
  const sacralscore = code.attributes[9].value;
  const vibescore = code.attributes[3].value;
  const freqscore = code.attributes[1].value;

  const [crownpct, setCrown] = useState(0);
  const [thirdpct, setThird] = useState(0);
  const [throatpct, setThroat] = useState(0);
  const [heartpct, setHeart] = useState(0);
  const [solarplexuspct, setSolar] = useState(0);
  const [rootpct, setRoot] = useState(0);
  const [sacralpct, setSacral] = useState(0);
  const [vibepct, setVibe] = useState(0);
  const [freqpct, setFreq] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setCrown(crownscore);
    }, 100);
    setTimeout(() => {
      setThird(thirdscore);
    }, 100);
    setTimeout(() => {
      setThroat(throatscore);
    }, 100);
    setTimeout(() => {
      setHeart(heartscore);
    }, 100);
    setTimeout(() => {
      setSolar(solarplexusscore);
    }, 100);
    setTimeout(() => {
      setRoot(rootscore);
    }, 100);
    setTimeout(() => {
      setSacral(sacralscore);
    }, 100);
    setTimeout(() => {
      setFreq(freqscore);
    }, 100);
    setTimeout(() => {
      setVibe(vibescore);
    }, 100);
  }, []);

  return (
    <div>
      <Nav />
      <div id="code-details-container">
        <div id="details-main-container">
          <div id="details-tile">
            <Img
              id="details-img"
              src={code.image}
            />
            <div id="details-name">
              {code.name}
            </div>
            <div id="details-alignment">
              Energy: {code.attributes[0].value} Centric
            </div>
            <div id="details-edition">
              Edition: {code.attributes[11].value}
            </div>
            <a id="download" href={code.image} target="_blank" rel="noopener noreferrer">
              HIGH RESOLUTION DOWNLOAD
            </a>
            <NavLink to="/mynfts" id="details-back"><FontAwesomeIcon icon={faArrowLeft} className="check" size="sm" /> Back</NavLink>
          </div>
          <div id="details-right">
            <div id="details-right-top">
              <div id="details-column1">
                <div id="chakra-levels">CHAKRA LEVELS</div>
                <div className="details-chakra-container">
                  <CrownProgress
                    size={50}
                    strokeWidth={5}
                    percentage={crownpct * 10}
                    color="#FF0073"
                  />
                  <div className="chakra-name">CROWN CHAKRA</div>
                  <div className="chakra-score">{crownscore}</div>
                </div>
                <div className="details-chakra-container">
                  <ThirdEyeProgress
                    size={50}
                    strokeWidth={5}
                    percentage={thirdpct * 10}
                    color="#E23EB3"
                  />
                  <div className="chakra-name">THIRD EYE CHAKRA</div>
                  <div className="chakra-score">{thirdscore}</div>
                </div>
                <div className="details-chakra-container">
                  <ThroatProgress
                    size={50}
                    strokeWidth={5}
                    percentage={throatpct * 10}
                    color="#00A1E7"
                  />
                  <div className="chakra-name">THROAT CHAKRA</div>
                  <div className="chakra-score">{throatscore}</div>
                </div>
                <div className="details-chakra-container">
                  <HeartProgress
                    size={50}
                    strokeWidth={5}
                    percentage={heartpct * 10}
                    color="#33AD00"
                  />
                  <div className="chakra-name">HEART CHAKRA</div>
                  <div className="chakra-score">{heartscore}</div>
                </div>
                <div className="details-chakra-container">
                  <SolarPlexusProgress
                    size={50}
                    strokeWidth={5}
                    percentage={solarplexuspct * 10}
                    color="#FFBD01"
                  />
                  <div className="chakra-name">SOLAR PLEXUS CHAKRA</div>
                  <div className="chakra-score">{solarplexusscore}</div>
                </div>
                <div className="details-chakra-container">
                  <SacralProgress
                    size={50}
                    strokeWidth={5}
                    percentage={sacralpct * 10}
                    color="#FF6900"
                  />
                  <div className="chakra-name">SACRAL CHAKRA</div>
                  <div className="chakra-score">{sacralscore}</div>
                </div>
                <div className="details-chakra-container">
                  <RootProgress
                    size={50}
                    strokeWidth={5}
                    percentage={rootpct * 10}
                    color="#FB0001"
                  />
                  <div className="chakra-name">ROOT CHAKRA</div>
                  <div className="chakra-score">{rootscore}</div>
                </div>
              </div>
              <div id="details-right-vline" />
              <div id="details-column2">
                <div id="details-vibration">VIBRATION</div>
                <ScoreProgress
                  size={180}
                  strokeWidth={15}
                  percentage={vibepct}
                  color="#FF84BB"
                />
                <div id="details-frequency">FREQUENCY</div>
                <FrequencyProgress
                  size={180}
                  strokeWidth={15}
                  percentage={(freqpct)}
                  color="#17E593"
                />
              </div>
            </div>
            <div id="details-right-hline" />
            <div id="details-right-bottom">
              <div id="energy-container">
                <div id="energy-logo" />
                <div id="energy-title-container">
                  <div id="energy-title">ENERGY ALIGNMENT</div>
                  <div id="energy-alignment">{code.attributes[0].value.toUpperCase()} CENTRIC</div>
                </div>
              </div>
              <div id="details-description">
                An NFT collection of 4,850 hand-painted codes from Sabet’s world-famous lovescript. Collecting a code is the beginning of our journey together. Each code contains hidden messages and healing properties that each collector must uncover. Holders will get access to online resources, as well webinars with Sabet and other leaders in the Healing and Wellness space. May these codes bring you love and abundance and unlock your greatest potential.
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>

  );
};

export default connect(null, null)(Code);
