import React, { useEffect, useState } from 'react';
import crown from '../../files/throat.png';

const CircularProgress = ({
  size, strokeWidth, percentage, color,
}) => {
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    setProgress(percentage);
  }, [percentage]);

  const viewBox = `0 0 ${size} ${size}`;
  const radius = (size - strokeWidth) / 2;
  const circumference = radius * Math.PI * 2;
  const dash = ((100 - progress) * circumference) / 100;

  return (
    <svg width={size} height={size} viewBox={viewBox}>
      <circle
        fill="none"
        stroke={color}
        cx={size / 2}
        cy={size / 2}
        r={radius}
        strokeWidth={`${strokeWidth}px`}
      />
      <circle
        fill="none"
        stroke="#E2dcdc"
        cx={size / 2}
        cy={size / 2}
        r={radius}
        strokeWidth={`${strokeWidth + 1}px`}
        transform={`rotate(-90 ${size / 2} ${size / 2})`}
        strokeDasharray={[dash, circumference - dash]}
        style={{ transition: 'all 1s' }}
      />
      <image
        x="50%"
        y="50%"
        href={crown}
        width="35"
        height="35"
        transform="translate(-17.5, -17.5)"
      />
    </svg>
  );
};

export default CircularProgress;
