/* eslint-disable max-len */
import React from 'react';
import Nav from '../components/nav';
import Footer from '../components/footer';

const rarity = (props) => {
  return (
    <div id="raritycontainer">
      <Nav />
      <div id="rarity-inner">
        <div id="rarity-title">RARITY BREAKDOWN</div>
        <div className="rarity-text">Healing Codes is a collection of 4,850 NFTs generated from 333 original pieces of Lovescript by Sabet. Each code is charged with healing energy, and this energy is represented by traits related to the chakra system, which defines energy points within a person&apos;s body. Here, we will explain how these traits are generated, and the rarity associated with them.</div>
        <div className="rarity-text">First, each code had to be assigned an edition number from 1 to 50, with the edition total of each code adding up to the 4,850 total number of NFTs. To generate the editions the high energy gamma probability distribution was used, with its shape and scale represented by the zodiac frequencies of the root and crown chakras, 228 and 216, respectively.</div>
        <div className="rarity-text">Now, onto the traits. Each code has a score from 1-10 for each of the seven core chakras, defining that chakra&apos;s power in this code. If a code&apos;s combined chakra score is over 58, and each chakra is individually 7 or higher, the code is at maximal energy, and assigned the maximum frequency of 963, which is the frequency associated with the crown chakra on the solfeggio scale. These are extremely rare, there are only 4 such codes in the entire collection. </div>
        <div className="rarity-text">Likewise, if a code has a score of 10 in one chakra, and a combined score less than 23, the code is in perfect alignment with the dominant chakra, and assigned that chakra&apos;s frequency on the solfeggio scale. The solfeggio scale is used for the frequency trait of the code as it is the set of frequencies most often associated with having healing properties.</div>
        <div className="rarity-text">If a code does not have one of these extremely rare chakra breakdowns, the frequency of the code is calculated using an algorithm that weights each chakra&apos;s strength and determines the frequency based on the solfeggio scale. There is a final numerical trait associated with each code, the code&apos;s vibration, that is determined using the code&apos;s frequency along with randomization techniques.</div>
        <div className="rarity-text">All of these traits, the chakra scores, frequency, and vibration of the code, are used in the final step to determine the single chakra the code is most closely aligned too. </div>
        <div id="rarity-last" className="rarity-text">The last trait associated with a healing code describes the way the script took shape as it flowed from the universe to the canvas. The majority of the codes are pure script, but the remaining 30 percent of the codes can be viewed as people, faces, creatures, plants, or words, each with varying rarity. </div>

      </div>
      <Footer />
    </div>

  );
};

export default rarity;
