import React from 'react';
import Img from 'react-cool-img';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

const GalleryTile = (props) => {
  const nonjpgurl = props.code.image.substring(0, props.code.image.length - 4);
  return (
    <NavLink to={`mycode/${props.code.id}`} id="link" className="gallery-code-container">
      <Img
        className="gallery-img"
        src={`${nonjpgurl}-min.jpg`}
      />
      <div className="tile-name">
        {`${props.code.name}`}
      </div>
      <div className="tile-alignment">
        Energy: {props.code.attributes[0].value}
      </div>
      <div className="tile-edition">
        Edition: {props.code.attributes[11].value}
      </div>
      <div className="bottom-banner">
        More Details
      </div>
    </NavLink>
  );
};

export default connect(null, null)(GalleryTile);
