/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable max-len */
import React from 'react';
import { NavHashLink } from 'react-router-hash-link';
import S4code from '../../files/S4code.png';

const section1 = (props) => {
  return (
    <div id="section4container">
      <div id="S4banner" />
      <iframe
        id="S4video"
        src="https://www.youtube.com/embed/rsHBFAiteRE?rel=0"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
      <div id="S4TC1">
        <div id="S4TC1title">UTILITY</div>
        <div id="S4TC1text">Healing Codes will be community-centered around healing and wellness. Each code will unlock access to a variety of online resources, as well as in-person seminars and gatherings. Collectors will be able to learn from Sabet and other renowned artists and spiritual leaders in the community. The codes themselves also have core utility. For many collectors, the symbols have enriched their personal and professional lives. The codes are meant to be meditated on, shared, and interpreted. Each collector will find deeper meanings within their code, unlocking a flow of love, healing, and abundance. We see Healing Codes growing into a community of love and light.</div>
      </div>
      <div id="S4rarity">
        <div id="S4TC2">
          <div id="S4TC2title">RARITY/ENERGY</div>
          <div id="S4TC2text">There will be 333 unique hand drawn codes in the Genesis collection. Each code will be a limited edition, ranging from 1/1 to 1/50. Each code will be given a rarity score based on eleven distinctive characteristics, for a total of 4,850 codes.</div>
          <NavHashLink
            smooth
            to="/rarities"
          > <div id="S4TC2subtitle">Learn More</div>
          </NavHashLink>
        </div>
        <img
          src={S4code}
          id="S4code"
        />
      </div>
    </div>

  );
};

export default section1;
